const LocationReviewsData = [
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Stacey Bird",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Jessica Rubio",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Jaclyn Day",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Jorge Hill",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Marcus H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Steven Obrien",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Melissa Coleman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dennis C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Kathryn Ramirez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wesley S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Caroline Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stacey C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Lori Lopez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lauren R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Misty Baker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Russell H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Robert Turner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Carla Nguyen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Victor M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Alex York",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Laura Dickson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Jessica Bennett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Albert R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Robert Martinez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maurice S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Amy Hall",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Timothy V.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Ashley Gomez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Allen B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Matthew Poole",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Beth J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Rachael Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "James Frey",
        "service_type": "Garage Door Repair",
        "tech_name": "Shannon R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Alexis Austin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katherine P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Megan Morton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Karen M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Katherine Schneider",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tanya M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Melissa Powers",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffery N.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Paul Flores",
        "service_type": "Garage Door Installation",
        "tech_name": "Heather V.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Mary Bartlett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Kimberly Chavez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Lori Baker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Hector P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Daniel Pacheco",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Katrina Meza",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Jeremy Cruz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Patrick George",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeremiah R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Charles Farrell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Frank B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Cassandra Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Garza",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Johnston",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Philip C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Susan Novak",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tracy A.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Mcdonald",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "James Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rebecca T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Terry Green",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephen L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Theresa Wu",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Miranda Jenkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jimmy M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Alexander Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rachel S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Matthew Mcdaniel",
        "service_type": "Garage Door Installation",
        "tech_name": "Stacy R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Kenneth Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Adam M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Steven Bright",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Brittney Noble",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Mackenzie Bennett",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michelle G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Laura Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Samantha Callahan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Karen Hunt",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kimberly King",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dawn G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Rebecca Thomas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Thomas Middleton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Victoria C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Lori Becker",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan K.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Riley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Chad J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Stephen White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brittney G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Andrew Baker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Donald R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Kenneth Simmons",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian N.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "James Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Martin K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Maria Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Peter W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Vickie Moody",
        "service_type": "Garage Door Off Track",
        "tech_name": "William H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Tanya Peterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kenneth M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Jacob Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Kathleen Mitchell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Adam W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Melissa Robinson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nathan Y.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Jay Orr",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Derek P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Edgar Cannon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Stacy Fuller",
        "service_type": "Garage Door Services",
        "tech_name": "Robert G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Stacey Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Mackenzie Morris MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Ali",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Debra Mitchell",
        "service_type": "Garage Door Repair",
        "tech_name": "Justin M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Mary Woods MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeffrey B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Stephen Avila",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephen D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Randy Rubio",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kari S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Jerry Terry",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Billy Reyes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Isaiah Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Luis S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Matthew Castaneda",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Erin N.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Luis Henderson",
        "service_type": "Garage Door Services",
        "tech_name": "Katie S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Anthony Hernandez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Arthur Fisher",
        "service_type": "Garage Door Services",
        "tech_name": "Rebecca T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Shannon Parker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stacy M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Kristen Hawkins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Benjamin M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Amanda Castillo",
        "service_type": "Garage Door Services",
        "tech_name": "Robert H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Rachel Mcguire",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Jerry Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Julia M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Jonathan Wallace",
        "service_type": "Garage Door Opener",
        "tech_name": "Rodney M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Tony Sanchez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brenda D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Michaela Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lori B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Susan Dunn",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jared P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Robert Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Connie H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Kara Adams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sara F.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Margaret Munoz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melanie F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Tonya Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tammy P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Virginia Ramirez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anthony D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Monique Mason",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Mr. Patrick Thompson MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Crystal S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Autumn White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Susan R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Curtis Reyes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shannon M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Austin Mathews",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Courtney L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Tina Horne",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Marilyn G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Casey Meyer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Sandra Thomas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cameron S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Kelly Delgado",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Darius Pham",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Jacob Walters",
        "service_type": "Garage Door Services",
        "tech_name": "Paul W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Jeremy Turner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nancy J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Ashley Richardson",
        "service_type": "Garage Door Repair",
        "tech_name": "April V.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Phyllis Clark",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Lori Roman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Susan N.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Barbara Stewart",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Briana E.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Brian Russell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Ian Adams",
        "service_type": "Garage Door Installation",
        "tech_name": "Vincent K.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Kyle Edwards",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Jeremy Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Michelle Cline",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Hector M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "David Wolfe",
        "service_type": "Garage Door Services",
        "tech_name": "Jared N.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Joshua Harmon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Theresa Mcintosh",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christine A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "John Ho",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alex D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Pamela Acosta",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Alan Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jasmine M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Rebecca Peterson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Diane B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Gina Ross",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Nicholas Chapman",
        "service_type": "Garage Door Repair",
        "tech_name": "Stacey W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Jose Cook",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Courtney V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Margaret Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Maria Robles",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sandra H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Sabrina Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Melanie H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Juan Campbell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laura R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Victor Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Tony Flores",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Caitlin H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Dr. Monica Thomas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christine H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Martha Green",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cynthia W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Phillip Hall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Susan P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "John Cohen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Sarah Ryan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marcus A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Sandra Soto",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Derek Martin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Frank W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Melinda Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Caitlyn E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Alicia Larsen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Meagan Wright",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Benjamin R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Vanessa Lawrence",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicole B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Dustin Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lacey H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Christopher Baker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelli J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "David Freeman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Kevin Adams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jamie O.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Anne Sutton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Dawn Carr",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Keith B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Patricia Gallagher",
        "service_type": "Garage Door Repair",
        "tech_name": "Phillip A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Christine Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Jamie Craig",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robin W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Lauren Heath",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andre S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Douglas Dillon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Devin B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Bryan Horton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joshua W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jacob Lucas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Holly Benson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Jessica Sims",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Angela H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Morris",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mark C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Julie Padilla",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sara H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Monique Allen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brenda L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Zachary Garcia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Melissa Middleton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Philip B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Daniel Mosley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Lauren Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Leslie D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Christina Nelson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Kelly Erickson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bobby S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Christopher Nunez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Sherri Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anita P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Diana Ibarra",
        "service_type": "Garage Door Installation",
        "tech_name": "Adam B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Brittney Hayes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Allison Nguyen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Patricia H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Colin Casey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Benjamin A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Matthew Marquez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Austin M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Amanda Castro",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "James Hines",
        "service_type": "Garage Door Installation",
        "tech_name": "Linda W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Kyle Powell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Heidi Taylor",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Salinas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ricky T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Sanchez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Susan K.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Terry Fletcher",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Matthew V.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Michael Graham",
        "service_type": "Garage Door Services",
        "tech_name": "Erik P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Jason Mcknight",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Doris R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Leslie Sims",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Virginia J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Angela Wright",
        "service_type": "Garage Door Opener",
        "tech_name": "Justin B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Dominic Gonzalez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Mr. Nathan Fisher",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Clayton H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "William Decker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Susan M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Christopher Mathis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mitchell F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Cynthia Trujillo",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Pamela W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Alfred Gutierrez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "April O.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Jill Lewis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joshua M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Alyssa Olson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jimmy N.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Olivia Gray",
        "service_type": "Garage Door Insulation",
        "tech_name": "Penny M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Kayla Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "John Wright",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Susan L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Donald Peterson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Edgar R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Charles Blevins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Emily H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Suzanne Vega",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sandra H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Lacey Parks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ronnie H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Adam Fowler",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tracy N.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Brenda Harris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Hunter P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Angela Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Larry J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Mrs. Tanya Richards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Katherine Frost",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Brittany Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lori P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Lisa Mccoy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mike M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Lozano",
        "service_type": "Garage Door Repair",
        "tech_name": "Douglas S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Marcus Shepherd",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mario V.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Gabriela Olson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa V.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Carol Leblanc",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "John King",
        "service_type": "Garage Door Installation",
        "tech_name": "Ronnie J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Amanda Carney",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brendan M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Rachel Miles",
        "service_type": "Garage Door Repair",
        "tech_name": "Carly S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Theodore Farley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tanya R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Samuel James",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stephanie J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Zachary Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Jose Nash",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Rhonda Silva",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Teresa Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephanie K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Douglas Duncan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Randall S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Courtney Santos",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Heather K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Kyle Nguyen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shannon H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Laura Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Lindsay Pena",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Patrick L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Kenneth Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Allison W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Emily Little",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Jason Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brad M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Alyssa Gray",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Wayne Stanley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Derek D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Brandon Barrera",
        "service_type": "Garage Door Off Track",
        "tech_name": "Katherine B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Ann Adams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tammy C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Zachary Brewer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tonya M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Samantha Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Hannah Lewis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tanya S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Kyle Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Mary Wells",
        "service_type": "Garage Door Off Track",
        "tech_name": "Abigail R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Benjamin Norris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ashley H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Jonathan Aguirre",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Renee P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Nathan Chambers",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Colin V.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "William Martin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Virginia S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Mary Hill",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lauren M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Lisa Rios",
        "service_type": "Garage Door Opener",
        "tech_name": "Victor W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Diana Lewis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Edward D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Robert Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Shaun E.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Perry Parker",
        "service_type": "Garage Door Repair",
        "tech_name": "Heather M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Kevin Hill",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Tiffany Guzman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Francisco J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Michelle Stewart",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Samantha M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Christine Powers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Greg S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Tim Shah",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gregg J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Carlos Bautista",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melissa H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "James White",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wayne S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Joshua Pollard",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christopher M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Travis Savage",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeff H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Kelsey Wolfe",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Chad R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Steven Mann",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joanna M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Kelly Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Melissa W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Lang",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "George W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Andrea Robbins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephanie S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Ray",
        "service_type": "Garage Door Installation",
        "tech_name": "Eric R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Derek Vazquez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Judy V.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "William Jenkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sharon R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Richard Henderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michelle G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "John Freeman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erin R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Tina Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sara R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Monica Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David I.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Michelle Roman",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah V.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Kevin Hughes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremy Y.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Nathaniel Schroeder",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tracy W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Edward Kim",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sheila G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Deborah Boyd MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rhonda P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Bethany Henderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Franklin Gross",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Trevor Kim",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "William Reynolds",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Donna O.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Andrew Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heidi S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Melinda Duncan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gary C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Robert Fuentes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Daniel Landry",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Melinda Romero",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Richard Miller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephen B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Carol Chaney",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tanya C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Alison Schwartz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Richard Hamilton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shawna K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Laurie Swanson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley V.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Sandra Sherman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Briana C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Sarah Maddox",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alexis O.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Robert Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Vanessa F.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Angel Peterson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeremiah S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Scott Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Holly Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kyle R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Singh",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Jason Woods",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Brittney Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gary M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "James Lopez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Samantha Washington",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Nicole Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Robin Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Ashley Callahan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela F.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Tony Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Yolanda Bowman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kerry G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Robert Perry",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Joseph Henderson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "April K.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Cindy Perez",
        "service_type": "Garage Door Installation",
        "tech_name": "Wayne N.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Ortiz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Kristy Haynes",
        "service_type": "Garage Door Installation",
        "tech_name": "Natalie H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Tammy Hall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Daniel Buck",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Paula Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David Q.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Stacie Mitchell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jim E.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "William Bell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Kimberly Pearson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandi R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Katherine Elliott",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Stephanie Richardson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Danielle B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Cory Bailey",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Christina Lawrence",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sheila W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Thomas Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Katherine M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Lisa Wyatt",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erica H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Christina Delgado",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samantha L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Debra Powell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Ronnie Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bryce C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Sara Wang",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Caitlin B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Ana Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amanda L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Kevin Beasley",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa Q.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Randall Vaughn",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Crystal M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Jose Barajas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica K.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Richard Glenn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Harry Duncan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Victoria B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Sherry Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Miss Erica Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Ricky Brewer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Steve Todd",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shane C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Daniel Hill",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Adam C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Steven Nichols",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Chelsea M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Suzanne Goodwin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Teresa G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Virginia Cox",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Blake Alvarez Jr.",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Theresa Green",
        "service_type": "Garage Door Services",
        "tech_name": "Caleb N.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Karen Griffith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shawn L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Riley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Regina Meyer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Sandra Black",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Penny C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Sarah Stafford",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Sandra Sandoval",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gene H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Gregory Potter",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kelly B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Alexis Mueller",
        "service_type": "Garage Door Services",
        "tech_name": "Nathan B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Zachary Chandler",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Emily B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Jesse Gomez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Philip Novak",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mary G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "James Lynn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jane M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Barbara Rivera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Suzanne N.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "William Ali",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Phillip A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Whitney Adams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrew T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "John Clark",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Patrick Shepherd",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Katherine R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Nicholas Rivers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anthony H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Patricia Hayes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "James Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Albert Mcdonald",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cody G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Kelly Lynn",
        "service_type": "Garage Door Insulation",
        "tech_name": "Susan A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Steven Cohen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Roger G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Gregory Gibson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrew R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Mrs. Valerie Bishop",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Todd G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Patrick Nguyen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bryan G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Thomas Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Angela Herrera",
        "service_type": "Garage Door Services",
        "tech_name": "Charles H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Michelle Gonzalez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sara F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Ryan Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Eduardo Clements",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Amy Eaton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nathan D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "William Pratt",
        "service_type": "Garage Door Opener",
        "tech_name": "Felicia M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Kelly Peterson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Lynn Simon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Kristen Roberts",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gary S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Glenn Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ralph B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Tracey Mcguire",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Marie P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Jeremy Watkins",
        "service_type": "Garage Door Opener",
        "tech_name": "Brenda R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Benjamin Dillon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jacob O.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Sarah Lam",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Steven Martin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Juan K.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "John Dunn",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dana W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Gina Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Edward Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Aaron J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Christian Schwartz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Patricia J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Nicholas Garcia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joshua J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Charles Lowe",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jay S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "John Carson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jose A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Steven Washington",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joyce M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Brooke Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Renee W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Anthony Lee",
        "service_type": "Garage Door Insulation",
        "tech_name": "Courtney L.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Alan Santana",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Pamela M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Michael Blankenship",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Janet Young",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emily R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Victor Park MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Travis C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Mary Perez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Raymond K.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Sabrina Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Toni W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Micheal Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sara S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Amanda Kelley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Wendy Howell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stacy M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Joseph Keith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Franklin V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Karen Cruz",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alexandra R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "John Leon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Richard M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Thomas Harrison",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Chris R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Ebony Salazar",
        "service_type": "Garage Door Opener",
        "tech_name": "Christy H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Deborah Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Miranda M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Crystal Strickland",
        "service_type": "Garage Door Services",
        "tech_name": "Robert R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Joseph Bell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Angela B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Scott Holloway",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Kimberly Simpson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexander P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Elizabeth White",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Tonya Rasmussen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "John Edwards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tamara M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "William Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Oscar C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Christopher Barnett",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Michael Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrea J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Jon Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sara R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Daniel Robertson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Patricia L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Sarah Clark",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Karina M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Paige Craig",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Debbie H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Krystal Armstrong",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Antonio J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jessica Moran PhD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah K.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Gabriela Baker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kara J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Mark Chase",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Douglas G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Joseph Dunn",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cindy L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Wendy Galvan",
        "service_type": "Garage Door Installation",
        "tech_name": "Miranda D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Jasmine Mason",
        "service_type": "Garage Door Services",
        "tech_name": "Ann W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Lisa Sawyer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph V.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Kelli Jimenez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Linda H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Erica Buchanan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nathan G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Susan Le",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Maria D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Casey Jordan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Charlene Diaz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Peter C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Brian Gomez",
        "service_type": "Garage Door Installation",
        "tech_name": "Marc J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Virginia Kim",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paula J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Rachel Bentley",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Albert Atkinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gregory B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Brittney Silva",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Jennifer Montgomery",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Danielle T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "John Cross",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sandra K.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Terrance Kramer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lisa S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "David Rodriguez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tim B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Timothy Chen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tommy H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Gregory Gaines",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Donna V.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Megan Santiago",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Charles Conley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amanda S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Stephanie Shaw",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dustin P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Scott Mcdonald",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tyler L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Brandi Munoz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Christine Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Lauren Moss",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Debra Foley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Erin Serrano",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher D.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Allison Martin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Evan P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Nathan Gallagher",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Audrey B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Jonathan Gomez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Theresa G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Hector Bell",
        "service_type": "Garage Door Repair",
        "tech_name": "Kelsey W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Allison Kirby",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Beth B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Crystal Stone",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Nancy Hurley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Crystal W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Michael Adams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tracey L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "James Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anne S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Robert Haas",
        "service_type": "Garage Door Installation",
        "tech_name": "Emily W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Vincent Winters",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandy D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Adrian Kelly",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Raymond S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Thomas Newton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "David Hayes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Patrick Ward",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gary S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Nicholas Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Barbara M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Robbins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rebecca M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Richard Bennett",
        "service_type": "Garage Door Opener",
        "tech_name": "James F.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Vincent Baker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "David Bullock",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Crystal H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Melissa Ross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Victoria D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Emily Mayer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William O.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Danielle Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Teresa H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Dr. Ronald Serrano",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dawn B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Pamela Henry",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Victor K.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Tina Jackson",
        "service_type": "Garage Door Services",
        "tech_name": "Robert D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Matthew Tucker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cameron A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Courtney Gray",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stefanie L.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Jonathan Boyd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anne J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Gary Davidson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Bryan White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cody F.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Eric Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Darren T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Pearson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cynthia R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Joel Griffith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Blake Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Anthony Cowan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Abigail G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Samantha Robinson DDS",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Robert Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Adrian P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Shirley Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Kevin Wright",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Randall H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Erika Howard",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Curtis H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Sandra Shaffer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dylan T.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "James Marsh",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Theresa R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Tammy Vance",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Elizabeth L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Amanda Jackson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Grant R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Felicia Solis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tina H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Dr. Linda Taylor MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "John Jenkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brenda F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Justin Kim",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Caitlin W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Stephanie Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Colleen M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Wendy Chandler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Shannon Davis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Sara Phelps",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "April Boyer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Keith A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Julia Salazar",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Ashley Frank MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Randall G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "James Martin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erica R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Pamela Lawson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robin J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Mark Kirby",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Karen A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Mr. Daniel James",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anna G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Jacob Fisher",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Meghan J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Debra Lowe",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andre B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Mr. Kevin Mann",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stacy R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Denise Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Makayla G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Christopher Ferguson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Pamela Marshall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nathan N.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Evelyn Harrison",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brianna D.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Tara Clark",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jason C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Julie Delacruz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Mary Spencer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sonia B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Julie Armstrong",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Timothy Wood",
        "service_type": "Garage Door Installation",
        "tech_name": "Gerald C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Robin Williamson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anthony C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Amber Crawford",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bobby J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "David Berry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laura P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Lindsay Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joanne B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Robert Koch",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Dr. Janet Gonzalez PhD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sarah P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Jesse Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Albert C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Jessica Flores",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amy C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Cody Clark",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Colleen A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Michael Willis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Matthew Perry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Felicia P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Yolanda Evans",
        "service_type": "Garage Door Repair",
        "tech_name": "Emily T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Michelle Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brenda H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "David Evans",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Kristina Wall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cindy T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Bruce",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gabrielle S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Steven Gross",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rhonda N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Ricky Wagner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher N.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Jorge Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carly L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Trevor Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Steve Short",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Natalie S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Amber White",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Diane R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Michael White",
        "service_type": "Garage Door Installation",
        "tech_name": "Wyatt Y.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Brandon Peterson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alyssa K.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Shawn Flores",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brittany A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Christopher Weaver",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rebecca C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Debra Young",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Madeline Yates",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carla S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Jason Frey",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Hailey Nelson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rita W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Christina Kelley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Ware",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Robert Cain",
        "service_type": "Garage Door Installation",
        "tech_name": "Kristine H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Watts",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura U.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Michael Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Zachary C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Joshua Kane",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Stephen Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tina B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Patrick Lynch",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Ryan Long",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shirley P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Justin Carter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Derek V.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Aaron Spears",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eddie F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Donna Ellis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gary F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Mario Murphy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Yolanda O.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Pamela Lopez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sandra H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Michelle Ramirez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Janice H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Donna White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lori T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Raymond Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eric S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Ann Tran",
        "service_type": "Garage Door Repair",
        "tech_name": "Desiree S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Kathy Carter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremy L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "William Graham",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tamara S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Michelle Lewis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rebecca P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Mary Hubbard DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Jonathan Garcia",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Diana Cantrell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christina F.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Sara Hancock",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Yolanda E.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Brenda Lee",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dawn N.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Robert Nelson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brandon N.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Patrick Pittman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Devin M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Kevin Wheeler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dennis S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Crystal Mclaughlin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samuel J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Timothy Ellis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rebecca B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Michelle Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Richard H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Melissa Hudson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason U.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Barbara Logan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Tyler Villarreal",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amy M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Adam Galloway",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Sara Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jerome H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Amber Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Raymond B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Joseph Sanchez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nancy V.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Ronnie Wright",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Alejandro Lane",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christine W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Matthew Osborn",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Samantha I.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Shaun Weber",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kim S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Dustin Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Zachary R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Natalie Walsh",
        "service_type": "Garage Door Off Track",
        "tech_name": "Roger G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Jason Sawyer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Steven R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Angela Hopkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Kim M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Jeremy Woodward",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Valerie F.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Gary Pineda",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Daniel Jimenez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Jeffery Richard",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jay W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Amanda Bird",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mitchell W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Lisa Schaefer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Mary Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cynthia C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Sean Newton",
        "service_type": "Garage Door Opener",
        "tech_name": "Alicia D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Sean Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Jose Taylor",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amanda J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "John Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Nancy Douglas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Patrick Valencia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicole D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "David Young",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Carl Torres",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Juan E.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Corey Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Tamara Ponce",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Renee H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Samantha Vasquez",
        "service_type": "Garage Door Installation",
        "tech_name": "Jon O.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Anthony Rodgers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bryan J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Karen Sims",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Monica B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Mary Martinez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tanya H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Robinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Ryan Hull",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Lisa Proctor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Hannah S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Jackie Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Glenn K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Laurie Torres",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "April Vance",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Maria L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Kristen Jimenez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Christopher Peterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Greg M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Lori Ruiz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Madison C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Robert Hoover",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "John Martinez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jack T.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Bobby Holt",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "John Garza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Pamela L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Leroy Rose",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sandra S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Shawn Young",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cody C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Nicole Arroyo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Caleb H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Eric Butler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lindsay S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Joshua Tyler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ellen P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Regina Edwards",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Steven Robinson",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Erin Sharp",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Oneal",
        "service_type": "Garage Door Insulation",
        "tech_name": "Pamela S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Louis Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Justin E.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Michael Carter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Russell W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Jasmine King",
        "service_type": "Garage Door Opener",
        "tech_name": "Kevin C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Cheryl Lara",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cassandra F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Stephen Phillips",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kimberly N.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "William Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Andrew Glover",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Diana W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Sharon Edwards",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cheryl B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Benjamin Ellis",
        "service_type": "Garage Door Installation",
        "tech_name": "David B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Brittney Haney",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christina S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Tyler Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Bryan Moyer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jacqueline P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Hancock",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Heather Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Marcia Warner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "William M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Sarah Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Karen H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Michael Baker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donna R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Mr. John Rush",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Mike Barnes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua Y.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "John Meza",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sean S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Taylor Goodwin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ernest M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "April Chaney",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tammy W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Melissa Myers",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "George Williamson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffrey D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Brian Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kimberly L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Richard Jordan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Darlene M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Michelle Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeremy P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Shannon Byrd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Kenneth Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tanya B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Larry Ellis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sharon M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Heather Turner",
        "service_type": "Garage Door Installation",
        "tech_name": "Jerome M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Kenneth Houston",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John V.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Alexandra Torres",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Mitchell Rollins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Bell",
        "service_type": "Garage Door Services",
        "tech_name": "Maria C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Miss Lauren Black",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer E.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Jacob Guzman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Chad H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Jacob Bailey",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrea M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Holly Bender",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelli N.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Gregory Tate",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Thomas H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Jon Taylor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Danielle Garza",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jason P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Vicki Powers MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Dylan F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Joshua Parker",
        "service_type": "Garage Door Opener",
        "tech_name": "Jill L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Paul Murphy",
        "service_type": "Garage Door Services",
        "tech_name": "Patricia C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Ms. Renee Paul",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "April W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Adrian Chapman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Angela M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Lori Whitehead",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristy P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Kristin Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Martha J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Eric Sullivan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dorothy C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jaime Cruz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gregory W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "David Rodriguez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Jonathan Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Justin J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Ryan Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Douglas H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Monique Pope",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rachel M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Terry Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leslie M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Joel Wilkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Julie B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Matthew Golden",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Priscilla R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Diana Norris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Caroline G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Andrea Blackburn DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sabrina G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Andrew Gutierrez",
        "service_type": "Garage Door Services",
        "tech_name": "Margaret R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Rich",
        "service_type": "Garage Door Services",
        "tech_name": "Robert H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Kirsten Yang",
        "service_type": "Garage Door Services",
        "tech_name": "Bethany M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Shelley Lee",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shannon D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Amy Harrington",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Calvin N.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Sara Olsen",
        "service_type": "Garage Door Opener",
        "tech_name": "Margaret G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Dakota Rosario",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katie M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Brian L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Brandon Mccoy",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Lisa Myers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Savannah C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Nicole Perez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patrick J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mccormick",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kenneth S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Kimberly Russell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Luis Roberts",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ronald A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Catherine Mccoy",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca K.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Amy Bridges",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrea F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Dawn Wilcox",
        "service_type": "Garage Door Off Track",
        "tech_name": "Heather W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Scott Price",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amanda G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Douglas Miller",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alexander P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "John Barnett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gregory M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Jane Duarte",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Darlene P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Gina Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mitchell R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Brian Hooper",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kathy G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Christopher Mitchell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Andrew Cook",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Nathan Gomez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Spencer M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Gina Travis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Darrell W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hickman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jamie C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Catherine Good",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Judy Cain",
        "service_type": "Garage Door Repair",
        "tech_name": "Craig R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Adam Baker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Debra Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Charles K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Lisa Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Teresa D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Melissa Ross",
        "service_type": "Garage Door Opener",
        "tech_name": "Crystal B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Austin Rivera",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Alexis Valencia",
        "service_type": "Garage Door Installation",
        "tech_name": "Blake T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Tiffany Hopkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Gregory Robinson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Kimberly Walker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kathryn N.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Michelle Diaz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Amy Parker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kyle L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Nathan Koch",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Leslie Scott",
        "service_type": "Garage Door Installation",
        "tech_name": "Amber H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Jimmy Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Tyler B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Eric Ball",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandon S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Joel Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joshua M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Rachael Martinez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "John Paul",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brittany C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Laura Patel",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Deanna Salas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sarah H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Arthur Parker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stacy L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Janice Bishop",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Martha A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Dana Golden",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Steve Bolton IV",
        "service_type": "Garage Door Services",
        "tech_name": "Cathy G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Melissa Clay MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kelly M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Thomas Wright",
        "service_type": "Garage Door Opener",
        "tech_name": "Kathryn P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Juan Anderson",
        "service_type": "Garage Door Services",
        "tech_name": "Jerry Y.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Olivia Robinson",
        "service_type": "Garage Door Repair",
        "tech_name": "Travis T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Theodore Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mark W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Kelly Holt",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Linda M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Haley Thompson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sheila B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Leah Morgan DDS",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amber D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Gregory Hays",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Megan Ellis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tina T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Richard Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michelle M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "James Adams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Phillip F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Shane Rios",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Audrey Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jonathan G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Anthony Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christine R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Robert Holt",
        "service_type": "Garage Door Opener",
        "tech_name": "Michelle M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Chad Wagner",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Dale Randall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kyle W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Vincent Richardson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Martha B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Mallory Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dawn H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Brittany Ochoa",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Robert Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amanda R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "John Woods",
        "service_type": "Garage Door Installation",
        "tech_name": "Melanie F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Maria Owen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Angela Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Timothy Daniel",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tony C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Rodney Dixon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amanda A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Melissa Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cassandra M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Roy Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Caroline H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Jonathan Wells",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Kevin Perez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Joe Ward",
        "service_type": "Garage Door Installation",
        "tech_name": "Shaun P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Miss Sarah Wilkerson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Debbie T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Roger Barnes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Amy Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Erica Perez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ricardo D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Paul Robbins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Harold S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Stephen Bell",
        "service_type": "Garage Door Off Track",
        "tech_name": "David D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Barbara Taylor",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Beverly F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Heather Rogers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Jean Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jasmine W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Felicia Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Ronald H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Mrs. Debra Harris",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Samuel M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jarvis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kenneth S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Sharon Aguilar MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rebecca A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Amber Rivera",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Deanna H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Joshua Nunez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Charles M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Mr. Steven Roberts",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mandy D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Christopher Chavez",
        "service_type": "Garage Door Services",
        "tech_name": "Caleb M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Stacy Elliott",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carla R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Susan Leonard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erin A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Nancy Carter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tina W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Nathaniel Wilson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Marcus M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Joshua Ray",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Norman Schultz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Meghan W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Dennis Rasmussen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tina P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Fred Parrish",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Andrew Howard",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elizabeth C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Derrick Hudson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephanie G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Dennis Tate",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel A.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Ashley Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "George R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Jill Wilkerson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Eduardo Mckinney",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Raymond Perez",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Michael Thomas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Victoria R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Natalie Young",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicholas A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Barbara Coleman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cheryl K.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Joseph Rose",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cassidy B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Shawn Dixon",
        "service_type": "Garage Door Installation",
        "tech_name": "Sarah R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kayla Cook PhD",
        "service_type": "Garage Door Opener",
        "tech_name": "Blake W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Krystal Gonzales",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Mark Ruiz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stacy H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Cole",
        "service_type": "Garage Door Opener",
        "tech_name": "Jo Y.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "James House",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Wesley Blanchard",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kathleen L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Cynthia Grant",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kristina B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Donna Simpson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Chad Wilson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Eric Donovan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Darren Richards",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patricia W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Richard Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shawn J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Philip Morris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Kenneth Strong",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Zachary Mcclure",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rebecca W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Robert Ross",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Olivia Vance",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Natalie Robertson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tiffany G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Gregory Burgess",
        "service_type": "Garage Door Installation",
        "tech_name": "Nathan H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Stephanie Vincent",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Bullock",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Alexandria Harrison",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Crystal Martin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Donald J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Kelli Campbell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Mark Fitzpatrick",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kim R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Kathy Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Daniel J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Robert Maxwell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kyle H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Logan Swanson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Jason Long",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gina M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Andrade",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gail P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Caitlin Hernandez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Michele Howell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laura B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Stephen Coleman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alice M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Lisa King",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Leslie C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Amanda Love",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angela W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Linda Bryant",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Victoria T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Lauren Walker",
        "service_type": "Garage Door Installation",
        "tech_name": "Timothy G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "John Thomas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nathaniel R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Jodi Edwards",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Christina Harrison",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melanie S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "James Hamilton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Liu",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ronald L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Diane Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Leslie S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Matthew Roberts",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chad M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Justin Weaver",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brenda H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Joseph Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cynthia W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Donald Ryan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kara L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Michelle Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Maria S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "James Caldwell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Tammy Campbell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Dorothy Williamson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mitchell P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Aaron Hughes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Marcus C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Banks DDS",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Blake M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Ann Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Danielle B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Mr. Jeffrey Hartman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mark J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Alexander Moore",
        "service_type": "Garage Door Opener",
        "tech_name": "Patricia C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Kelly Hamilton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melissa P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Eric Swanson",
        "service_type": "Garage Door Opener",
        "tech_name": "Pamela L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Mrs. Sheila Holder",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nathan R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Dr. Leonard Webb",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephen C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Jermaine Briggs",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Anne Christian",
        "service_type": "Garage Door Repair",
        "tech_name": "Loretta M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jennifer Stone",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robyn P.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Gina Schmitt",
        "service_type": "Garage Door Installation",
        "tech_name": "Suzanne C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Terry Foster",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Jacob Hall",
        "service_type": "Garage Door Services",
        "tech_name": "Meghan N.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "James White",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Allison W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Brianna Compton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Thomas Moore",
        "service_type": "Garage Door Installation",
        "tech_name": "Carla C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Marissa Montes",
        "service_type": "Garage Door Insulation",
        "tech_name": "James R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "James Bell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicole C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Michael Parker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kiara J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Allison Riley",
        "service_type": "Garage Door Repair",
        "tech_name": "Courtney J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Zachary Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Dana W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Nolan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Susan M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Walter Olsen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Kimberly Martin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Breanna W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Jose Potts",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Victoria Jimenez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Ryan Castro",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Angela H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Stacey Reed",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bryan W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Jennifer Mcgrath",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Makayla Wagner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Danielle S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Ryan Bailey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tamara R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Steven Spencer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michelle F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Vincent Davies",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nathan S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Catherine Vargas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jasmine K.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Shirley Vargas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Andrew Campos",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexandria L.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Jason Keller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "James Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Timothy P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Tracy Espinoza",
        "service_type": "Garage Door Services",
        "tech_name": "Deborah B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Kimberly Roberts",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Barbara G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Marcus Thomas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michelle T.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Jason Lutz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Todd B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Dr. Jasmine Adams",
        "service_type": "Garage Door Repair",
        "tech_name": "Cameron R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Jay Gates",
        "service_type": "Garage Door Services",
        "tech_name": "Chris B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "James Pitts",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mackenzie O.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Omar Morton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heidi K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Christopher Robinson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William E.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Bradley Hill",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jaime B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Frederick Pugh",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Ebony Cobb",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeffery G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Valerie Young",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Teresa O.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Taylor Cantrell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samantha F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Morse",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Paul Hammond II",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Philip P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Mary Durham",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tara M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Patricia Rush",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kimberly F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Courtney Myers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Scott Conner",
        "service_type": "Garage Door Services",
        "tech_name": "Brian L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Chad Hughes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Vanessa G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Jeffery Green",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacob T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Justin Phillips",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ruth J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Stephanie Taylor",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Christian Jenkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Keith C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Amy Watson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Rebecca Pittman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gene D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Laura Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samuel G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Robert Holt",
        "service_type": "Garage Door Repair",
        "tech_name": "Carrie B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Michael Glass",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lindsay F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Steven Murillo",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Susan T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Alison Lester",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maurice P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Paul Patel",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Teresa M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Michael Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Kathleen Willis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kathryn G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Kristopher Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrea M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Michael Crawford",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Todd T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Michael White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Michael Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tyler F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Carla Woodard",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kyle H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Robert Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "John Paul",
        "service_type": "Garage Door Repair",
        "tech_name": "Shannon P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hart",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Heather A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Michelle Jenkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "George Walker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Victor S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Bailey Carter",
        "service_type": "Garage Door Repair",
        "tech_name": "Cindy K.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Robert Mitchell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Wendy H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Laura Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erica L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Mark Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sandra B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Veronica Hickman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kelly W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Ali",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lisa C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Mary Thomas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Holly T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Jennifer Peck",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kevin F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Ian Wheeler",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sylvia M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Chelsea Brown",
        "service_type": "Garage Door Services",
        "tech_name": "David G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Natalie Stevens",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Jamie Mckinney",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Timothy Schultz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Dennis Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Adam Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Paula S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Tara Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Deborah Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andrew P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Tammy Mcneil",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian O.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Angela Parsons",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Douglas C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Robert Chase",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Priscilla H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Patrick Greer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cynthia S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Carrie Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jacob G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Susan Moore",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Monica Patrick",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Leonard K.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Hannah Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Ana Ross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicole V.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Sarah Vang",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Kimberly Hunter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrea H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Stacy Meadows",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffrey L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Shawn Gray",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Paul M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Eric Sandoval",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ronald L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Kyle Levy",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danielle M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Karen Clark",
        "service_type": "Garage Door Services",
        "tech_name": "Yolanda M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Kelly Hubbard PhD",
        "service_type": "Garage Door Services",
        "tech_name": "Kelsey F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Christy Welch",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kara W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Nicole Dawson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lindsay S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Bridget Jenkins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rebecca C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Sean Newman",
        "service_type": "Garage Door Repair",
        "tech_name": "Allen C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "April Lopez",
        "service_type": "Garage Door Installation",
        "tech_name": "Micheal D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Washington",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Michael Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Darren S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Brenda Young",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Elizabeth M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Rebecca Sanchez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alexandra S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Mary Thomas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Danielle J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Mary Ingram",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Dr. Megan Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joyce A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Joshua Alexander",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexandra J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Aaron James",
        "service_type": "Garage Door Insulation",
        "tech_name": "Madison W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Melissa Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dominique W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Johnny Foster",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Richard Kelly",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Meghan D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Laurie Grant",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Connie E.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Justin Wells",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Walter H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Natalie Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Teresa H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Christina Butler",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christian F.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Sheena Sutton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Timothy G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Shannon Byrd",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jackie J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Angela Jimenez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Wendy Z.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Joanne Bartlett",
        "service_type": "Garage Door Opener",
        "tech_name": "Kristina J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Tanya Frank",
        "service_type": "Garage Door Installation",
        "tech_name": "Kenneth D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Scott Craig",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Anthony Walker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Deanna J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Rebecca Hudson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Heather S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Melissa Cardenas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Molly Hudson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brent F.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Michael Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Vanessa J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Darryl Cowan",
        "service_type": "Garage Door Services",
        "tech_name": "Michael C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Derek Ellis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Carol Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anna S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Julie Good MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Ricardo West",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Maria N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Jessica Mullins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Erika W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Benjamin Ward",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ethan B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Derek Jefferson",
        "service_type": "Garage Door Opener",
        "tech_name": "Kathryn T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Steven Perkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Elizabeth O.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Lindsey Shelton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sean J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Jeffery Wilson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew K.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Jason Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Rachel H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Kevin Evans",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dawn H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Melvin Hall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paul H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Erik Francis",
        "service_type": "Garage Door Installation",
        "tech_name": "Victoria P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Cassie Young",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Dylan Hall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Lauren Hicks DVM",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mark R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "James Garza",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erin R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Sierra Clay",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Stephen Clark",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katherine A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Andrew Berry",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dawn M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Jeremy Farmer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Emily Garza",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Marissa G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Kelly Webb",
        "service_type": "Garage Door Insulation",
        "tech_name": "Susan C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Maria Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Michelle James",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeffrey L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Megan Munoz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephanie W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Jonathan Barrett",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Justin E.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Patricia Ruiz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Derek K.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Johnathan Murphy",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffery S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Brian Ballard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Anita Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Dawn M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "George Rasmussen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Raymond Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "George R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Debra Shea",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jason S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Lawrence Pierce",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Charles M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Rebecca Weeks",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Emily Trevino",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Roger H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Katie Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Micheal Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patricia R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Julie Potts",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melinda D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Joseph Graham",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Stacy Campbell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mariah S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Brianna Hebert",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Barbara R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "James Berry",
        "service_type": "Garage Door Opener",
        "tech_name": "Kelly W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Cassie Watkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Jacob Blair",
        "service_type": "Garage Door Repair",
        "tech_name": "Brett H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Todd Montgomery",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Janice J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Nancy Ward",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Joshua Hall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andre E.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "John Bailey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Deborah D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Brian Combs",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeffrey P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Kristopher Oliver",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Hunter Scott",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Raymond W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Spencer Perez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cheryl E.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Rachel Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Gary Myers",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tara K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "James Garcia",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jacqueline A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Anne Bryant",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kim G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Gregory Franklin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cody S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Ashley Munoz",
        "service_type": "Garage Door Services",
        "tech_name": "Cassie H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Marisa Decker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Dr. Michael Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Virginia B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Jeffery Gaines",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lisa B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Mark Thompson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Linda S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Samantha Barajas",
        "service_type": "Garage Door Repair",
        "tech_name": "Elaine B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Michael Hernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Seth M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Eddie Gallagher",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brittney M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Spencer Leonard",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Kathleen Huang",
        "service_type": "Garage Door Services",
        "tech_name": "Sarah M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "David Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Edward J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Donald Nelson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Wayne G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Derek Torres",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anna P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Joseph Higgins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Amy Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Judy H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Christian Cochran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Nicole Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Laura M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Daniel Alvarado",
        "service_type": "Garage Door Services",
        "tech_name": "Megan J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Jennifer Oliver",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dawn M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Susan Becker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mike M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Matthew Elliott",
        "service_type": "Garage Door Services",
        "tech_name": "Amanda B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Javier Cameron",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Diana Kerr",
        "service_type": "Garage Door Services",
        "tech_name": "Russell G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Gonzalez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danielle C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Jennifer Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gabrielle L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Brittany Torres",
        "service_type": "Garage Door Installation",
        "tech_name": "Grace B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Michael Jackson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Daniel Newman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kathy W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Caleb Jennings",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Tyler Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Barbara K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Angela Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ashley M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "David Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Leah R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Geoffrey Woods",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shannon R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Chapman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Jon Anderson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brenda M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Katherine Campbell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melissa B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Colleen Sanders",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joseph V.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Erica Blackwell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bradley P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Mark Potter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amber E.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Ashley Alexander",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gary W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Michael Tanner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Benjamin T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Meredith Potts",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melissa S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Calvin King",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Felicia Y.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Heidi Bowman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Heather G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "April Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Susan Hendrix",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michele W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Jason Mcguire",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Edward V.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Jasmine Webb",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Oscar Cruz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sandra C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Alyssa Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Donna B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Stephanie Serrano",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christine O.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Heather Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ann E.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Robert Valentine",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Misty Romero",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carol M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "John Pierce",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Stacy Hines",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristin V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Kimberly Martin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ronald R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "William Reyes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Charlene Young",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Jamie Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Melissa Walters",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sean D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Paul Hart",
        "service_type": "Garage Door Opener",
        "tech_name": "John O.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Terri Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Carla Adams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Alexander Stephens",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kaitlin B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Tyler Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melinda S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Travis Delgado",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Charles R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Cindy Kerr",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Corey B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Ashley Romero",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Luke Turner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Laura P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Christy Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brittany J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Julie Peterson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Andrea Doyle",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Julie H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Diane Jefferson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeffrey B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Mary Daniel",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Zachary O.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Robert Watson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sarah E.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Brady Proctor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shawn A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Russell Ruiz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brenda S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Nicole Russell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James K.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Lisa Dudley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brenda F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Regina Fox",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rachael L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Justin Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Valerie R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Matthew Haas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Deanna L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Dana Navarro",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Angela R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Richard Conrad",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ian B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Maureen Scott",
        "service_type": "Garage Door Services",
        "tech_name": "Savannah T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Erin Baldwin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cheryl W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Rachel Wright",
        "service_type": "Garage Door Installation",
        "tech_name": "David M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Kevin Wilkerson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sean H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Meghan Simmons",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Alexander Little",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sara W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Aaron White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cheryl G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Kimberly Pierce",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Susan W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "John Ellison",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Jennifer Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Kathy Berry",
        "service_type": "Garage Door Installation",
        "tech_name": "Patrick F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Ernest James",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Melissa Casey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Meredith A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Kevin Santiago",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Linda Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amanda M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Christian Marshall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kari L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Brett Mahoney",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Randy S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Dominic Walker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Jessica Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Justin C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Laura Randall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Casey H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Brandon Mckinney",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sean K.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Evelyn Powell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Elizabeth M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Ryan Clarke",
        "service_type": "Garage Door Installation",
        "tech_name": "Brandon W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Edward Jimenez",
        "service_type": "Garage Door Opener",
        "tech_name": "Allison G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Nancy Gallagher",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "David Riley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Hannah W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Ernest Oliver",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Ryan Rosales",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Meredith E.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Megan Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Deborah Singh",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Daniel Butler",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Monica W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Miss Rebecca Weber",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jacob R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Timothy Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Angel Dunn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David T.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Tracy Reynolds",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Alec Keller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Curtis C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Travis Romero",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Julia G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Patricia Warren",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Karen Rogers",
        "service_type": "Garage Door Installation",
        "tech_name": "Eric L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Emily Turner",
        "service_type": "Garage Door Repair",
        "tech_name": "James D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Henry Lewis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sandra C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Stephanie Harvey",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Patrick Maxwell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shannon C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Whitney Macdonald",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gary L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Jacob Gonzales",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Penny M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Sonya Hicks",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paul T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Sarah Choi",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Kristy Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Blake Palmer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melanie S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Megan Sanders",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Darrell S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Jeanette Duke",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ann B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "James Baker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jim M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Christopher Gonzalez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Matthew Austin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Tyler Greene",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Aaron D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Megan Wells",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrew C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "John Delgado",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Maria Y.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "David Hall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William K.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Nguyen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gail J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Heather Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anna P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Brian Tate",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Laura T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "David Edwards",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christian P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Kayla Estrada",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Richard M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Stephen Buckley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ronnie H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Kelly Stanley",
        "service_type": "Garage Door Services",
        "tech_name": "Isaac M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Anthony Yang",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tyler G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Rose Moran",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jesse F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Michael Marshall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Frank H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Kristina Sanford",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cody M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Diane Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Eric Hudson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Abigail P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Melissa Calderon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Annette H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Cody Young",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Darrell M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Marissa Robinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Katherine W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Michelle George",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Monica W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Kathleen Skinner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nathaniel K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Sara Dixon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kristin W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Robin Salas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Terri S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Steven Ramos",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Corey A.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "David Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Corey M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Stephanie Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Barbara Rogers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lindsey M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Keith Nichols",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Timothy W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Sarah Phillips",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Veronica H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Mary Mcdaniel",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Bruce Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Terri R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Lydia Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jared G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Rebecca Todd",
        "service_type": "Garage Door Services",
        "tech_name": "David A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Bobby Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Blake A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Kim Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Justin G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Michael Contreras",
        "service_type": "Garage Door Repair",
        "tech_name": "Aaron Y.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Julie Martin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Dale Cortez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paul B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Jonathan Crosby",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Howard C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Levi Nguyen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Deborah M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Megan Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Haley B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Alec Dennis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven F.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Melanie Rice",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Desiree S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Sydney Page",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Dwayne Wilson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Regina S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Miguel Kramer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Maria J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "James King",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patrick N.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Cassandra Cross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael N.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Michael Moreno",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Beth B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Kari Wallace",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Dylan Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Melinda Ortiz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicole R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Lisa Moore",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Sean Campbell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michele L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Jeremy Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Candace W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Sydney Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Douglas Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicole W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "William Murillo",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jodi M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Amber Rubio",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joel Y.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Caitlin Sanchez",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Rebecca Perkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bridget S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Rebecca Herman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Megan D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Rita Pennington",
        "service_type": "Garage Door Insulation",
        "tech_name": "Courtney J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "James Collins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Scott J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Bobby Gardner",
        "service_type": "Garage Door Services",
        "tech_name": "Philip C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Heather Cooper",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Natalie Cole",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Frank Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Patricia H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "David Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Raymond W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Derrick Ramos",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Javier S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Jeff Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Larry G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Joseph Gallegos",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Teresa C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Jaime Hill",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Audrey J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Joel Young",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephen S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Caitlin Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Marcus Y.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Richard Caldwell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Terry F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Willie Duncan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stacey M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Daniel Powell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alex C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Derrick Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ryan K.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Keith Espinoza",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Ashley Calderon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Keith H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Riley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lori B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Kathy Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Logan M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Melissa Rose",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shirley H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Anderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Deborah B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Michael Gomez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Patrick L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Jessica Glover",
        "service_type": "Garage Door Installation",
        "tech_name": "Isaac W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Vincent Sanders",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Linda J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Michael Fisher",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Victoria P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Samantha Gonzalez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rhonda C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Megan Parker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Steven Cole",
        "service_type": "Garage Door Opener",
        "tech_name": "Maria T.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Richard Henson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Brian Ross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Scott V.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Mason Mcconnell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrea W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Patricia Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Emily Rasmussen",
        "service_type": "Garage Door Services",
        "tech_name": "Sherri M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "David Peck",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristen R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Edward Olson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Jocelyn Williamson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ralph K.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Kelsey Gallagher",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Eric B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Eric Brown",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shelia B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Thomas Serrano",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Valerie C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Amy Cooper",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Michelle Whitehead",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cindy A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Amber Baxter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Richard Bowen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Victoria M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Matthew Huynh",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joel J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Martin Daniels",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrew C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Robin Banks",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathy P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Edward Schneider",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Candice H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Pedro Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Zachary Hernandez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ryan S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Amy Winters",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Laura W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Thomas Hodges",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Justin L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Mr. Jonathon Reyes",
        "service_type": "Garage Door Repair",
        "tech_name": "Nathan S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "John Villarreal",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrea B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Susan Williamson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sherry F.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Juan Hall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Tyler Welch",
        "service_type": "Garage Door Services",
        "tech_name": "William C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Olivia Medina",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Raymond F.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "David Cox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joel A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Susan Wiley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Karen Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ashley S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Sandra Oliver",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Julie M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Michael Bean",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stanley G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Susan Porter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kimberly A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Tonya Garza",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jamie P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Miss Susan Molina",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Donald Waters",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexis C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Tracy Murphy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Adam O.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Pamela Bishop",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shane W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Jerome Hughes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Lori Calhoun",
        "service_type": "Garage Door Installation",
        "tech_name": "Dana M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Jennifer George",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephen L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Wanda Larson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jose B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "David Clark",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Morgan Moreno",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brittany S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Thomas Sampson",
        "service_type": "Garage Door Installation",
        "tech_name": "Julie B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Angela Ward",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Wendy P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Emily Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Jesse Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sergio P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Julie Roth",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stacy S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Kayla Huber",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Timothy J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Dawn Thomas",
        "service_type": "Garage Door Services",
        "tech_name": "Karen C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Pamela Griffin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Karen Thomas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chelsea J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Daniel Ross",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Julie Cooper",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Javier J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Lee Adams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Maria G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "John Flores",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Miguel B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Michael Scott",
        "service_type": "Garage Door Opener",
        "tech_name": "Jason L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Michelle Mack",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Francisco R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Chad Stewart",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Dr. Jennifer Salinas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mark M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Melissa Castillo",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alicia J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Valerie Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Justin G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Brian Hall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Michael Higgins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Isaac Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Mark Dougherty",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard V.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Keith Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Howard H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Barbara Jennings",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cheryl W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Kevin Hogan DDS",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Charlene Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amanda J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Allison Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin N.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Kimberly Bennett",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Christopher Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rachel M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Hannah Young",
        "service_type": "Garage Door Repair",
        "tech_name": "Dustin C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Timothy Aguilar",
        "service_type": "Garage Door Insulation",
        "tech_name": "Peter M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Heather Perez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrea N.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Michael Garcia",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Theresa M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Deborah Jenkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Marvin Taylor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nancy W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Nancy Barber",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeff H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Dawn Duran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alan S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Jack Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrew C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel N.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Denise Deleon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jared C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "James Dillon",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kathryn S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Roger Hanson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amy V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Kayla Welch",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brenda T.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Tamara Guzman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James A.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Peggy Bryan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dawn S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Mrs. Dana Barton",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeremy R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Samuel Dawson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Caleb S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Stephanie Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Allison C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Rick Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Julie C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Sherri Reeves",
        "service_type": "Garage Door Repair",
        "tech_name": "Victoria P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Steven Brock",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Johnathan H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Donald Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Keith D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Eric Bryan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cory W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Karl Ryan",
        "service_type": "Garage Door Opener",
        "tech_name": "Frank M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Morgan Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Justin G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Joseph Bridges",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Keith F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Betty Hodges",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marilyn O.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Elaine Calderon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Bryan Barber",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sonya M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Jennifer Harmon DVM",
        "service_type": "Garage Door Opener",
        "tech_name": "Katherine C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laura Y.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Fred Guerra",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicholas H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Christy Henry",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Antonio B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Natalie Rodriguez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joel B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Tracy Robertson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandy T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Wendy Roach",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Catherine S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Monica Daniels",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Brandon Garza",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Jorge Hardy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Peter C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Joseph Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samantha L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Tracy Strickland",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cody F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
